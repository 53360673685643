

.graphicHead {
  text-align: center;
  margin: 40px 0;
}
.graphicHead h2 {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: #231f20;
  padding: 0 100px;
  position: relative;
  text-transform: uppercase;
  margin: 0 10px;
  word-break: break-word;;
}
.graphicHead h2:before,
.graphicHead h2:after {
  content: " ";
  width: 89px;
  height: 37px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  top:50%;
  margin-top: -18px;
}
.graphicHead h2:before {
  background-image: url(images/heading-left-new.png);
  left: 0;
}
.graphicHead h2:after {
  background-image: url(images/heading-right-new.png);
 right: 0;
}
.moreLink .p-button {
  font-weight: bold;
  color: #facb33;
  cursor: pointer;
  margin: 15px 0 0 0;
  font-size: 12px;
  background-color: transparent;
}
.moreLink .p-button.p-button-link:enabled:hover {
  color: #facb33;
}
.moreLink .pi {
  margin-left: 5px;
  transition: all 0.2s ease-in-out;
}

.moreLink:hover .pi {
  transform: translateX(5px);
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  border: 2px solid #aaa;
}
.p-carousel .p-carousel-content .p-carousel-prev .pi,
.p-carousel .p-carousel-content .p-carousel-next .pi {
  color: #aaa;
  font-weight: bold;
  font-size: 12px;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  background-color: transparent;
  border: 2px solid #aaa;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #a59157;
}
.p-dialog {
  border: solid 5px #755a34;
}
.p-dialog .p-dialog-header,
.p-dialog .p-dialog-footer {
  border-radius: 0;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  background-color: #ab8b38;
  color: #fff;
  opacity: 0.6;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  font-weight: bold;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  opacity: 1;
  background-color: #ab8b38;
  color: #fff;
}
/**Header**/
.mainHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #f1f2f6;
  box-shadow: 0 6px 6px -6px #000;
  height: 64px;
  z-index: 50;
  border: 0;
  border-radius: 0;
}
.mainHeader a {
  color: #232124;
  padding: 5px 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.mainHeader a:hover,
.mainHeader a.active {
  color: #a59157;
}

.mainHeader .brand {
  background-image: url(images/logo.png);
  width: 170px;
  height: 60px;
  margin: 0 45px;
  background-size: cover;
  text-indent: -9999px;
}
.react-multi-carousel-list {
}
.react-multi-carousel-dot-list {
}
.react-multi-carousel-dot button {
}
.react-multi-carousel-dot--active button {
  background-color: #ab8b38;
}


/**Main Carousel**/
.mainCarousel {
  padding: 10px 0 10px 0;
}
.promoSlide{height: 550px;position: relative;}
.mainCarousel .promoSlide img {

  display: block;
  max-height: 500px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: contain;
}

.mainCarousel .promoSlide h3 {
  font-size: 16px;
  text-align: center;
  padding: 15px 5px;
  margin: 0;
  font-weight: 600;
}

/**Achievement starts**/
.TextureBg {
  background-image: url(images/textureBg.jpg);
  padding: 40px 0;
}

.achievementsCarousel {
}

.achievementsCarousel .p-carousel-items-content {
  margin: 0 auto;
}
.achievementsCarousel .achievement-image {
height: 100%;
text-align: center;
}
.achievementsCarousel .achievement-item {
  box-shadow: 0 2px 12px 0 rgb(0 0 0);
  margin: 10px;
}
.achievementsCarousel .achievement-item-content {
  background-color: #fff;
}
.achievementsCarousel .thumbImage {
  position: relative;
  height: 160px;
}
.achievementsCarousel .thumbImage img{
 max-width: 100%;
 object-fit: contain;
 height: 160px;
 margin: 0 auto;
}

.achievementsCarousel .thumbContent {
  border: 1px solid #d1d5ec;
  border-top: 0;
  padding: 20px 15px;
}
.achievementsCarousel .thumbContent h4 {
  color: #244575;
  font-size: 16px;
  font-weight: 800;
  padding: 5px 0 5px 28px;
  background-image: url(images/headIcon.png);
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0 0 10px 0;
}
.achievementsCarousel .thumbContent .p-disabled {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  height: 80px;
  overflow: hidden;
}

.achievementsCarousel .carousel-container {
  max-width: 90%;
  margin: 0 auto;

  overflow: hidden;
}

/**About css**/
.aboutContainer {
  padding: 10px 0 50px 0;
  position: relative;
}


/**Activity container starts**/

.activitiesCarousel {
  background-color: #e8ecee;
  padding: 10px 0 50px;
}

.activitiesCarousel .imageContent {
  position: relative;
  padding: 20px;
}
.activitiesCarousel .imageContent img{display: block;width: 100%;}
.activitiesCarousel .imageContent:before,
.activitiesCarousel .imageContent:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  background-size: cover;
  background-image: url(images/frame-top.png);
}
.activitiesCarousel .imageContent:before {
  top: 0;
  left: 0;
}
.activitiesCarousel .imageContent:after {
  transform: rotate(180deg);
  bottom: 0;
  right: 0;
}
.activitiesCarousel .textContent {
  padding: 20px 0 0 0;
  text-align:center;
  
}

.activitiesCarousel .textContent h3 {
  color: #244575;
  font-size: 24px;
  line-height: 1;
  margin: 0 0 0 0;
  font-weight: normal;
}

.activitiesCarousel .textContent .p-disabled {
  font-size: 12px;
  line-height: 20px;
height:200px;
overflow:hidden;
text-align: center;
}

/**Event section**/
.eventCarousel {
  background-color: #f2f2f2;
  padding: 10px 0 50px;
}
.eventCarousel .carousel-container {
  max-width: 90%;
  margin: 0 auto;
}

.eventTile {
  padding: 0 5px 40px 5px;
  position: relative;
}
.eventTileContent {
  margin: 0 10px;
  background-color: #fff;
  padding: 30px 15px;
  transition: all 0.3s ease-in-out;
}
.eventTileContent h4 {
  color: #254576;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.eventTileContent .p-disabled {
  font-size: 12px;
  line-height: 18px;
  height: 80px;
  overflow: hidden;
}
.dateAndLoc .pi {
  color: #face3f;
  vertical-align: middle;
}
.dateAndLoc span {
  margin-left: 5px;
}
.dateAndLoc .location{
  margin-top:10px;
}
.eventTileContent .dateAndLoc span {
  font-size: 12px;
 
}
.eventTileContent .dateAndLoc .location span{
  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
}


.eventTileContent:hover {
  -webkit-box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
}
.achievement-item-content .dateAndLoc span {
  font-size: 12px;
}
.achievement-item-content .dateAndLoc .location span{
  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
}
.p-dialog  .dateAndLoc .location span{word-break: break-word;}

/**Footer css**/
.FooterSection {
  background-color: #011728;
  padding: 60px 0.5rem 60px 0.5rem;
}
.footerLogo {
  background-image: url(images/smallLogo.png);
  width: 100px;
  height: 100px;
  background-size: cover;
  text-indent: -9999px;
}
.FooterSection h5 {
  color: #b6b3b3;
  font-weight: 800;
  padding: 5px 0 5px 28px;
  background-image: url(images/headIcon.png);
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0 0 10px 0;
  font-size: 14px;
  text-transform: uppercase;
}
.FooterSection ul {
  padding: 0 0 0 28px;
}
.FooterSection ul li {
  font-size: 14px;
  color: #b6b3b3;
  font-weight: 400;
  margin: 0 0 5px 0;
  cursor: pointer;
}
.FooterSection .soicalLinks ul li {
  color: #b5a046;
}
.FooterSection .soicalLinks ul li .pi {
  font-size: 20px;
  cursor: pointer;
}

.FooterSection .footnote {
  color: #666666;
  font-size: 12px;
  margin: 0 0 5px 0;
}
.FooterSection address {
  font-size: 14px;
  color: #b6b3b3;
  font-weight: 400;
  padding: 0 0 0 28px;
  font-style: normal;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 30;

  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.overlay.showLayer {
  visibility: visible;
}

/**Location page**/
.aboutLocation {
  position: relative;
}
.aboutLocation  textarea{
 color: #fff;
}
.locationCarousel{}
.locationSlide {
  position: relative;
  min-height: 150px;
}
.locationSlide img {
  display: block;
  /* width: 100%; */
  max-height: 500px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: contain;
}

.locationSlide .mazarName {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 15px;
  text-align: center;
}
.locationSlide .mazarName p {
  color: #766a46;
  font-size: 18px;
  padding: 5px 0 0;
  margin: 0;
}
.locationSlide .mazarName .pi {
  font-size: 24px;
  color: #fff;
}
.aboutLocation h3 {
  color: #fff;
  font-family: "Roboto", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.aboutLocation p {
  color: #fff;
  line-height: 1.5;
}
.aboutLocation .graphicHead h2 {
  color: #fff;
}
.mazarLogo {
 
}
.mazarLogo img{
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
}
.tabsSection {
  background-color: #e9ecf1;
  padding: 50px 0;
}
.p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
}
.p-tabview-nav {
  justify-content: center;
}
.p-tabview .p-tabview-nav {
  position: relative;
  align-items: center;
  border: 0;
}
.p-tabview .p-tabview-nav:before,
.p-tabview .p-tabview-nav:after {
  content: "";
  position: relative;
  width: 89px;
  height: 37px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
}
.p-tabview .p-tabview-nav:before {
  margin-right: 5px;
  background-image: url(images/heading-left-new.png);
}
.p-tabview .p-tabview-nav:after {
  margin-left: 5px;
  background-image: url(images/heading-right-new.png);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #8f8f8f;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  margin: 0 10px;
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background-color: transparent;
  border: 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #071f3d;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #facb33;
}
.innerContent {
  max-width: 1240px;
  margin: 0 auto;
}
.contentBox {
  background-color: #fff;
  padding: 25px;
  position: relative;
  height: auto;
}
.rightContentBox.contentBox {
  height: 75%;
}
.contentBox h3,
.eventSection h3 {
  color: #766a46;
  margin: 0 0 0 0;
  font-size: 20px;
  font-weight: 600;
}
.contentBox textarea {
  color: #756623;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3em;

}

.placeWrapper{margin-top: 20px;}
.placeWrapper .imageBox {
  width: 52px;
  height: 52px;


  margin-right: 10px;
}
.placeWrapper .imageBox img{border-radius: 50%;  width: 52px;
  height: 52px;}
.placeWrapper .descriptionBox{width: 100%;}
.placeWrapper .descriptionBox h4 {
  padding: 0;
  margin: 0;
}

.placeWrapper .descriptionBox p {
}
.announcementSection .p-disabled{font-size: 16px;line-height: 1.5;color: #495057;}
.crownBg {
  background-image: url(./images/boxGraphic.png), url(./images/boxGraphic.png),
    url(./images/boxGraphic.png), url(./images/boxGraphic.png);
  background-position: -40px -40px, right -40px top -40px,
    left -40px bottom -40px, right -40px bottom -40px;
  background-repeat: no-repeat;
  border: solid 1px #ae904d;
}
.viewPdfBtn {
  position: absolute;
  top: 16px;
  right: 45px;
  width: 32px;
  height: 27px;
  background: url(./images/viewPdf.png) no-repeat;
  cursor: pointer;
}
.rightContentBox .customScroll {
  width: 100%;
  height:425px;
}
.rightContentBox.h-full .customScroll { 
  height:600px;
}
.leftContentBox .customScroll {
  width: 100%;
  height: 330px;
}

.customScroll .p-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
}

.customScroll .p-scrollpanel-bar {
  background-color: #a59157;
  opacity: 1;
  transition: background-color 0.3s;
}

.customScroll .p-scrollpanel-bar:hover {
  background-color: #135ba1;
}

.QRwrapper {
  margin: 10px 0 0 0;
  background-color: #f4f8fb;
  padding: 10px;
  height: 24%;
}
.QRwrapper .grid {
  height: 100%;
}
.QRwrapper .p-disabled, .QRwrapper .p-component:disabled {
  color: #838586;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  min-height:75px;
  line-height: 1;;
}
.QRwrapper img {
  display: block;
  width: 100%;
}
.eventSection h3 {
  margin: 30px 0 20px 0;
}
.eventSection .eventTile {
 
}
.eventSection .eventTile .eventTileContent {
 
}
.peakPeriod {
  background-color: #fff;
  padding: 40px 15px;
}
.peakPeriod h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.peakPeriod .chips {
  margin: 0 10px 15px 0;
  font-size: 14px;
  background: #E7ECEF;
    padding: 10px 25px;
    border-radius: 40px;
   display: inline-block;
}
.mazarContact p {
  color: #777;
  margin: 0 0 20px 0;
}
.feedbackBtn {
  padding: 40px 0 0;
}

.mapContainer iframe {
  border: 0px;
  width: 100%;
}
.bankIcon {
  border: 1px solid #000;
  padding: 10px;
  border-radius: 50%;
}
.bankIcon:before {
  font-size: 24px;
}
.mobileAccordion {
 
  display: none;
}

.allLocations{
  background-color:#FAFAFA;
  padding: 10px 0 50px 0;
}
.locationThumb{position: relative;}
.locationThumb figure {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0;
  margin: 0;
  height: 268px;
}
.locationThumb figure:hover {
  box-shadow: 0px 15px 35px -25px rgb(0 0 0 / 75%);
}
.locationThumb figure:hover figcaption i {
  transform: translateX(10px);
}
.locationThumb figure img {
  width: 100%;
  max-height: 100%;
  display: block;
  object-fit: contain;
  margin: 0 auto;
}
.locationThumb figure figcaption {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(1, 0, 34, 0.7);
  padding: 10px;
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.locationThumb figure figcaption i {
  color: #facb33;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.mapWrapper{background-color: #E7ECEF;padding: 10px 0 50px 0;}
.mapWrapper iframe{width: 100%;border: 0;}

.calendarWrapper{
  background-color: #011728;
  padding: 10px 0 50px;
}

.calendarWrapper h2{
  color:#FFCA00
}
.pdfAndFeedback{padding: 10px 0 50px;}
.pdfIcon img{width: 70px; cursor: pointer;}
.feedbackIcon img{width: 70px;}


.mazarContact .socialLinks{margin: 0;padding: 0;list-style: none;}
.mazarContact .socialLinks li{display:inline-block;margin: 0 5px;}
.mazarContact .socialLinks li span{display: block;cursor: pointer;} 
.galleryWrapper {
 
  margin: 0 0 25px 0;
}
.galleryWrapper figure img {
  max-height: 285px;
}
.galleryWrapper .carouselWrapper{
  width: 100%;
  margin: 0 auto;
}
.galleryWrapper .carousel-container{width: 85%;margin:0 auto;overflow: hidden;}
.galleryWrapper .carouselWrapper .react-multiple-carousel__arrow--left{left: 0;}
.galleryWrapper .carouselWrapper .react-multiple-carousel__arrow--right{right:0}
.galleryTab .carouselWrapper{
  width: 80%;
  margin: 0 auto;
}
.galleryTab .carouselWrapper .carousel-container {width: 90%;margin: 0 auto;}
.galleryTab .carouselWrapper .react-multiple-carousel__arrow--left{left: 0;}
.galleryTab .carouselWrapper .react-multiple-carousel__arrow--right{right: 0;}
.gallerySlide {min-height:300px;}
.gallerySlide h3{margin: 0 0 20px 0;text-align: center;}
.gallerySlide img{max-height:300px;display: block;margin: 0 auto;object-fit:contain;max-width: 100%;}
.galleryTab .gallerySlideTab img{max-height:300px;display: block;margin: 0 auto;object-fit:contain;max-width: 100%;}
.gallerySlideTab{position: relative;min-height: 385px;}
.gallerySlideTab .p-image-preview-container{width: 100%;}
.weatherAndMisc{
  padding: 15px;
  background-color:rgba(0, 0, 0, 0.75) ;
}

.miscTimeBtn.p-button.p-button-link,
.miscTimeBtn.p-button.p-button-link:hover{color: #fff;text-decoration: none;}
.miscTimeBtn .pi{color: #facb33;}

.weatherWrapper .rw-container-main{display: none;}
.weatherWrapper .rw-container{box-shadow: none;}
.weatherWrapper .rw-forecast-days-panel{background-color: transparent;}
.weatherWrapper .rw-forecast-date,
.weatherWrapper .rw-forecast-desc,
.weatherWrapper .rw-forecast-range{
  color:#fff;
}
.upArrow{
  background: #65c372;
}
.downArrow{
  background: #f16363;
}
.checkBoxalign{
  margin: 0px 0px 0px 12px !important;
}
.filterCheck{
  margin: 0px 20px 0px 0px;
    font-weight: 500;
    font-size: 16px;
}
.filterSearch{
  padding: 0.1rem 1.25rem !important;
}