.loginPage,
.launchPage {
  background-image: url("../../images/loginBG.jpg");

  min-height: 100%;
  background-size: 100% 100%;
}
.loginPage .selectUser {
  margin-bottom: 1rem;
}
.loginPage .selectUser .label {
  display: inline-block;
  color: #957d0d;
  margin-right: 2rem;
}

.loginPage .wrapper,
.launchPage .wrapper {
  padding: 4rem 2rem;
}
.loginPage .logoImage,
.launchPage .logoImage {
}
.logoImage img{width: 7rem;}
.loginPage .wrapper {
  border: 1px solid #d1c293;
  background: url(../../images/boxGraphic.png) left -39px top -39px no-repeat,
    url(../../images/boxGraphic.png) right -39px top -39px no-repeat,
    url(../../images/boxGraphic.png) left -39px bottom -39px no-repeat,
    url(../../images/boxGraphic.png) right -39px bottom -39px no-repeat, #ffffff;
  padding: 1.5rem;
}
.loginPage .formWrapper {
  position: relative;
  padding: 30px 0 0 0;
}
.loginPage .formWrapper .error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: red;
  margin: 0;
}
.loginPage .form-group {
  margin-bottom: 1rem;
}
.loginPage .form-control {
  border-radius: 0;
  border: 2px solid #eee8d7;
  color: #483704;
  padding: 0.2rem 0.4rem 0.2rem 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 3rem;
}

.loginPage .form-control:focus {
  box-shadow: none;
  border-color: #071f3d;
}
.usrimg {
  background-image: url("../../images/userNameIcon.png");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 45px;
}
.pswdimg {
  background-image: url("../../images/pwdIcon.png");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 45px;
}

.loginPage .btn {
  background-color: #071f3d;
  color: #f9c119;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.loginPage .btn:hover {
  background-color: #f9c119;
  color: #071f3d;
}

.loginPage .btnSmall {
  padding: 0.2rem 1rem;
  font-size: 0.875rem;
}

.loginPage .btn-bordered {
  background: #e2e7ea;
  color: #483704;
  border: 1px solid #ccd0dd;
}

.loginPage .btn.focus,
.loginPage .btn:focus {
  box-shadow: none;
}
.loginPage .navbar.fixed-bottom {
  background-color: rgba(0, 0, 0, 0.5);
  color: #dddddd;
padding:0 5px;
  z-index: 5;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.loginPage .navbar.fixed-bottom a {
  color: #dddddd;
  text-decoration: underline;
}
.loginPage label{
        min-width: 100px;
        display: inline-block;
}