body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif; */
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444;
    font-size: 14px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  *:focus {
    box-shadow: none !important;
  }
  p{line-height: 1.5;}
  .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 10px;
  }
  .grid-container {
    max-width: 1064px;
    padding: 0 0.5rem;
  }
  .p-component {
    font-family: "Roboto", sans-serif;
  }
  .p-disabled, .p-component:disabled{
    opacity: 1;
    width: 100%;
    resize: none;
    border-radius: 0;
    border: 0;
    display: block; 
    padding: 0;
    cursor: auto !important;
    pointer-events: auto;
    font-size: 14px;
    line-height: 1.5;
    background-color: transparent;
    margin: 10px 0;
    height: auto;
  }
  .p-image-preview-container{
    width: 100%;
  }
  .p-image-toolbar{z-index: 100;}
.middleContainer {
    margin-top: 64px;
  }
  
  .btnLarge {
    padding: 15px 85px;
  }

  .carouselWrapper{position: relative;max-width: 1240px;margin: 0 auto;}
  .carouselWrapper .react-multi-carousel-list{position: unset;}
  .react-multiple-carousel__arrow{border:1px solid #f9c119; min-width: 30px;
    min-height: 30px;
  
    border-radius: 50%;
    background-color: transparent;}
  .react-multiple-carousel__arrow:before{color: #f9c119;  font-size: 16px;}
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right{z-index: 10;}

  .carouselWrapper .react-multiple-carousel__arrow--left{left: 10px;}
  .carouselWrapper .react-multiple-carousel__arrow--right{right: 10px;}
  .react-multiple-carousel__arrow:hover{background-color: transparent;}
  .controlButtonsSmall .p-button.p-button-icon-only.p-button-rounded {
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 0 5px;
    /* z-index: 10; */
  } 
  .controlButtonsSmall .p-button.p-button-sm .p-button-icon{font-size: 12px;}

  .p-fluid .p-button{
    width: auto;
  }