@media only screen and (max-width: 600px) {


  /**/
}

@media only screen and (max-width: 990px) {
  .navigation {
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  .navigation.on {
    max-height: 1000px;
    
  }
  .mobileLogo{
    background-image: url(../../images/logo.png);
    width: 110px;
    height: 40px;
    background-size: contain;
    margin: 0 auto;
  }
  .mainHeader a {
    display: block;
    border-bottom: 1px solid #232124;
    padding: 15px;
  }
  .mainHeader .brand {
    display: none;
  }
  .hamburgerWrapper {
    padding: 13px 15px;
    position: relative;
  }
  .hamburger{position: absolute;right: 15px;top:15px}
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #b5a046;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
  /**/
  .promoSlide{height: 400px;}
  .mainCarousel .promoSlide img{max-height:350px;}
  .footerLogo {
    margin: 0 auto;
  }
  /* .tabsSection{
    display: none;
  } */

  .mobileAccordion {
    background-color: #e9ecf1;
    padding: 25px 15px;
    display:block;
  }
  .mobileAccordion .p-accordion .p-accordion-content {
    background-color: transparent;
  }
  .activitiesCarousel{
    padding:10px 10px 25px ;
  }
  .QRwrapper{height: auto;}
  .gallerySlideTab{min-height:500px;}
  .galleryTab .carouselWrapper{width: 100%;}
  .galleryTab .carousel-container{width:90%;margin:0 auto;}
  .contentBox{padding: 15px;}
  .galleryWrapper .carousel-container{width: 75%;}
  .rightContentBox.contentBox{height: auto;}
  .p-accordion .p-accordion-content{padding: 1.25rem 0;}
  .galleryTab .carouselWrapper .carousel-container{width:100%;padding-top:25px;}
  .galleryTab .react-multiple-carousel__arrow--left, 
  .galleryTab .react-multiple-carousel__arrow--right{top:0}
}
@media only screen and (max-width: 768px) {
.locationThumb figure{height: 160px;}
.graphicHead h2{padding:0 70px;font-size: 18px;}
.graphicHead h2:before, .graphicHead h2:after{width: 60px;height: 25px;margin-top:-12px;}
}